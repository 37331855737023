import React from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {css} from '@emotion/react'
import styled from '@emotion/styled'

import {Button} from '@daedalus/atlas/Button'
import {Divider} from '@daedalus/atlas/Divider'
import ContentWrapper from '@daedalus/atlas/helpers/ContentWrapper'
import {getBrand} from '@daedalus/core/src/brand/modules/selectors'
import FormattedMessageWrapper from '@daedalus/core/src/localization/components/FormattedMessage'
import {getAnonymousId} from '@daedalus/core/src/utils/anonymousId'

import {
  getApplication,
  getBookingStatus,
  getFreezeSelectedContext
} from '../../modules/selector'
import {
  ApplicationNames,
  closeFreezeConfirmationOverlay
} from '../../modules/slice'
import {getBookingManagementUrl, getSearchUrl} from '../../utils/url'

const StickyContainer = styled.div(
  ({theme}) => css`
    position: sticky;
    bottom: 0;
    margin: 0 -16px -16px; /* Overlap BottomSheetContent's padding */
    background-color: ${theme.colors.background.neutral.c000};
  `
)

const ButtonsWrapper = styled.div(
  ({theme}) => css`
    display: flex;
    flex-direction: column;
    gap: ${theme.layout.spacing.s400}px;
  `
)

interface Props {
  isOverlayContent?: boolean
}

export const CtaButtons: React.FC<Props> = ({isOverlayContent}) => {
  const bookingStatus = useSelector(getBookingStatus)
  const {hotel, searchDetail} = useSelector(getFreezeSelectedContext)
  const dispatch = useDispatch()
  const brand = useSelector(getBrand)
  const {name: application, bookingManagementUrl} = useSelector(getApplication)

  const bookingId = bookingStatus?.id
  const anonymousId = getAnonymousId()

  const goToSearch = () => {
    if (application === ApplicationNames.Search) {
      dispatch(closeFreezeConfirmationOverlay())
      return
    }

    const searchUrl = getSearchUrl(
      brand.address,
      hotel?.objectID as string,
      searchDetail.checkIn,
      searchDetail.checkOut,
      searchDetail.roomsSplit
    )
    location.assign(searchUrl)
  }

  const goToFrozenDeal = () => {
    if (!bookingId || !bookingManagementUrl) return

    const myBookingUrl = getBookingManagementUrl({
      hostUrl: bookingManagementUrl,
      bookingId,
      anonymousId
    })

    location.assign(myBookingUrl)
  }

  const buttonsWrapper = (
    <ButtonsWrapper>
      <Button
        dataId="ViewFrozenDeal"
        variant="special"
        fullWidth
        size="xl"
        onClick={goToFrozenDeal}
      >
        <FormattedMessageWrapper
          id="df.viewFrozenDeals"
          defaultMessage="View frozen deals"
        />
      </Button>
      <Button variant="secondary" fullWidth size="xl" onClick={goToSearch}>
        <FormattedMessageWrapper
          id="df.backToSearch"
          defaultMessage="Back to search"
        />
      </Button>
    </ButtonsWrapper>
  )

  return isOverlayContent ? (
    buttonsWrapper
  ) : (
    <StickyContainer>
      <Divider />
      <ContentWrapper paddingX="s500" paddingTop="s500" paddingBottom="s600">
        {buttonsWrapper}
      </ContentWrapper>
    </StickyContainer>
  )
}
