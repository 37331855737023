import {brands, defaultBrand} from '../../brand/config'
import {getUrlParam} from '../../utils/url'
import {
  getBrandOverrideFromCookie,
  persistBrandOverrideOnCookie
} from '../business/persistData'
import {Brand, BrandCode} from '../types'

const PROD_ENVIRONMENTS = ['prd', 'production']
/**
 * Checks whether the passed-in brand has CookiePro enabled
 */
export const checkIsCookieProEnabledForBrand = (brand: Brand) =>
  Boolean(brand.cookieProId)

/**
 * Checks whether the passed-in brand is Vio
 */
export const checkIsBrandVio = (brand: Brand) => brand.code === 'vio'

/**
 * Checks whether the passed-in brand is Etrip
 */
export const checkIsBrandEtrip = (brand: Brand) => brand.code === 'etrip'

/**
 * Checks whether the passed-in brand is GoSeek
 */
export const checkIsBrandGoSeek = (brand: Brand) => brand.code === 'goseek'

/**
 * Checks whether the passed-in brand is Kiwi
 */
export const checkIsBrandKiwi = (brand: Brand) => brand.code === 'kiwi'
/**
 * Checks whether the passed-in brand is DealBase
 */
export const checkIsBrandDealbase = (brand: Brand) => brand.code === 'dealbase'

/**
 * Checks whether the brand is internal (Vio, Etrip, GoSeek)
 */
export const checkIsBrandInternal = (brand: Brand) => brand.isInternal

/**
 * Checks whether the passed-in brand is a meta-to-meta brand
 */
export const checkIsBrandOffersLockedByDefault = (brand: Brand) =>
  brand.offersLockedByDefault

/**
 * Array containing all the partner brands codes
 */
export const partnerBrands = (() =>
  (Object.keys(brands) as BrandCode[]).filter(
    brandKey => !checkIsBrandInternal(brands[brandKey])
  ))()

/**
 * Returns the brandCode for the matching partnerKey or "vio" if no matching partnerKey is found
 */
export const getBrandCodeFromPartnerKey = (partnerKey?: string): BrandCode => {
  for (const brandCode in brands) {
    const typedBrandCode = brandCode as BrandCode

    if (brands[typedBrandCode].partnerKey === partnerKey) {
      return brands[typedBrandCode].code
    }
  }
  return brands.vio.code
}
/**
 * Retrieves the brand code from the URL or cookies, with consideration for demo environments
 * and production settings.
 *
 * @param processENV - The consumer process environment (e.g., 'production', 'prd').
 *
 * @returns brandCode- The determined brand code.
 */
export const getBrandCodeFromUrlOrCookie = (processENV: string) => {
  const {hostname} = location
  if (!hostname) return defaultBrand.code
  const hostnameParts = hostname.split('.')
  const brandCodes = Object.keys(brands) as BrandCode[]

  const brandOverride =
    (getUrlParam({
      search: location.search,
      param: 'brandOverride'
    }) as BrandCode) || getBrandOverrideFromCookie()

  const brandIsDemo = hostnameParts.some(part => part.includes('demo'))

  const envIsProduction = PROD_ENVIRONMENTS.includes(processENV)

  const brandOverrideIsValid =
    !envIsProduction && Boolean(brandOverride) && Boolean(brands[brandOverride])

  const getBrandCode = () =>
    (hostnameParts.find(part =>
      brandCodes.find(code => part.includes(code))
    ) as BrandCode) || defaultBrand.code

  const brandCode = brandIsDemo ? 'demoBasic' : getBrandCode()

  if (brandOverrideIsValid) {
    persistBrandOverrideOnCookie(brandOverride)
    return brandOverride
  }

  return brandCode
}

export const getBrandThemeName = (brandCode: BrandCode) => {
  return brands[brandCode]?.theme || brands.vio.theme
}
